import { AfterViewInit, Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ClientService } from 'src/app/services/client.service';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { FormulaireService } from '../../services/formulaire.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/* TRAITEMENT FICHIER EXCEL */
import * as XLSX from 'xlsx';
import { Error } from 'tslint/lib/error';
type AOA = any[][];
/* TRAITEMENT FICHIER EXCEL */


@Component({
  selector: 'app-tableaudebord',
  templateUrl: './tableaudebord.component.html',
  styleUrls: ['./tableaudebord.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class TableaudebordComponent implements OnInit, AfterViewInit {

  idClient: string;
  nomprenoms: string;

  client: any = [];
  listeClient: any = [];
  pronistic: any = [];
  listePronostic: any = [];
  listeChevaux: any = [];
  urllist: any = [];

  displayedColumns = ['nomprenoms', 'email', 'mobile', 'ville', 'formule', 'dateexpire', 'status', 'boutons'];

  displayedColumns2 = ['date', 'depart', 'course', 'hypodrome', 'boutons'];

  displayedColumnsattele = ['ch_numero', 'ch_cheval', 'ch_sa', 'ch_dist', 'ch_driver', 'ch_entraineur', 'ch_gains', 'ch_c', 'ch_poids', 'ch_valeur'];

  displayedColumnsurl = ['url', 'boutons'];

  dataSource = new MatTableDataSource(this.listeClient);
  dataSourceQuintePronostic = new MatTableDataSource(this.listePronostic);
  dataSourceCheveau = new MatTableDataSource(this.listeChevaux);
  dataSourceurl = new MatTableDataSource(this.urllist);

  @ViewChild('pclient') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('ppronostic') paginatorpronostic: MatPaginator;
  @ViewChild(MatSort) sortpronostic: MatSort;
  @ViewChild('pcheveau') paginatorCheveau: MatPaginator;
  @ViewChild(MatSort) sortCheveau: MatSort;
  @ViewChild('purl') paginatorurl: MatPaginator;
  @ViewChild(MatSort) sorturl: MatSort;



  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSourceQuintePronostic.paginator = this.paginatorpronostic;
    this.dataSourceQuintePronostic.sort = this.sortpronostic;
    this.dataSourceCheveau.paginator = this.paginatorCheveau;
    this.dataSourceCheveau.sort = this.sortCheveau;
    this.dataSourceurl.paginator = this.paginatorurl;
    this.dataSourceurl.sort = this.sorturl;


    this.paginator._intl.itemsPerPageLabel = 'Elements par page';
    this.paginator._intl.nextPageLabel = 'Suivant';
    this.paginator._intl.previousPageLabel = 'Précedent';

    this.paginatorpronostic._intl.itemsPerPageLabel = 'Elements par page';
    this.paginatorpronostic._intl.nextPageLabel = 'Suivant';
    this.paginatorpronostic._intl.previousPageLabel = 'Précedent';

    this.paginatorCheveau._intl.itemsPerPageLabel = 'Elements par page';
    this.paginatorCheveau._intl.nextPageLabel = 'Suivant';
    this.paginatorCheveau._intl.previousPageLabel = 'Précedent';

    this.paginatorurl._intl.itemsPerPageLabel = 'Elements par page';
    this.paginatorurl._intl.nextPageLabel = 'Suivant';
    this.paginatorurl._intl.previousPageLabel = 'Précedent';

  }


  IDSuppression = '';
  isLoadingResults = true;
  rep: any;
  parametreformuleForm: FormGroup;
  listeFormule: any;

  cheveauForm: FormGroup;
  isLoadingcheval = true;
  isLoadingurl = true;

  affichePronostic = false;
  afficheResultat = false;
  affichePartant = false;
  reponse;
  dateheure;
  nouveauquinte = true;

  q_jour: any;

  toto = 1;

  isLoadingquinte = true;
  isLoadingPronostic = true;
  isLoadingResultat = true;
  isLoadingPartant = false;

  pronosticForm: FormGroup;
  partantForm: FormGroup;



  listecourse = [
    { "course": "COURSE D'ATTELE" },
    { "course": "COURSE DE MONTE" },
    { "course": "COURSE DE PLAT" },
    { "course": "COURSE DE STEEPLE CHASE" }
  ];

  /* TRAITEMENT FICHIER EXCEL */
  data: AOA = [];
  afficherTable = 0;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName = 'SheetJS.xlsx';
  /* TRAITEMENT FICHIER EXCEL */

   estadmin = ''; 

  constructor(
    public configService: ConfigService,
    public userService: UserService,
    private router: Router,
    private clientService: ClientService,
    private toastr: ToastrService,
    private formulaireService: FormulaireService,
    private formBuilder: FormBuilder
  ) {
    /*this.dataSource = new MatTableDataSource(this.listeClient);
    this.dataSourceQuintePronostic = new MatTableDataSource(this.listePronostic);
    this.dataSourceCheveau = new MatTableDataSource(this.listeChevaux);
    this.dataSourceurl = new MatTableDataSource(this.urllist);*/
  }

  ngOnInit() {
    this.profil();
    this.raffraihiListe();

    this.initpronosticForm();
    this.initparametreForm();
    this.initcheveauForm();

    this.initpartantForm();
  }

  profil() {
    this.userService.readoneUtilisateur(localStorage.getItem('id')).subscribe(ret => {
      this.reponse = ret;
      if (this.reponse.success == true) {
        this.estadmin = this.reponse.results.liste[0].Util_tech;
      } 
  })
}

  onFileChange(evt: any) {
    this.afficherTable = 0;
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.afficherTable = this.data.length;
      console.log(this.data[0][0]);
      console.log(this.data[0][1]);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  export(): void {
    for (let item in this.data) {
      console.log(this.data[item][0], this.data[item][1], this.data[item][2], this.data[item][3], this.data[item][4], this.data[item][5], this.data[item][6]);
    }

    for (var counter: number = 1; counter < 10; counter++) {
      console.log("for loop executed : " + counter);
    }

    //generate worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    //generate workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // save to file
    XLSX.writeFile(wb, this.fileName);
  }

  appuiPronostic() {
    // alert("pronostic");
    this.listeQuintePronostic();
  }

  appuiParametre() {
    //alert("Parametre");
    this.listeurl();
  }
  appuiClient() {
    //alert("Client");
    this.listeClientPronostic();
  }

  raffraihiListe() {
    this.loadlisteFormulaire();
    this.listeClientPronostic();
    this.listeurl();
    this.listeQuintePronostic();
  }

  ouvrenouveauquinte() {
    this.nouveauquinte = true;
    this.resetformenrquinte();
  }

  resetformenrquinte() {
    this.affichePronostic = false;
    this.afficheResultat = false;
    this.affichePartant = false;
    this.pronosticForm.reset();
    this.dateheure = Date.now();
    this.pronosticForm.get('q_id_manuel').setValue(this.dateheure);
  }

  initpartantForm() {
    this.partantForm = this.formBuilder.group({
      partant_id_manuel: ['', Validators.required],
      partant_fichierexcel: ['', Validators.required]
    });
  }

  initparametreForm() {
    this.parametreformuleForm = this.formBuilder.group({
      param_id: [''],
      param_lienbaniere1: ['', Validators.required]
      /* id_formule: [''],
       f_index: ['', Validators.required],
       f_designation: ['', Validators.required],
       f_description: ['', Validators.required],
       f_prix: ['', Validators.required],
       f_periodicite: ['', Validators.required],
       f_offre: ['', Validators.required]*/
    });
  }

  initcheveauForm() {
    this.cheveauForm = this.formBuilder.group({
      id_chevaux: [''],
      ch_numero: ['', Validators.required],
      ch_cheval: ['', Validators.required],
      ch_sa: ['', Validators.required],
      ch_q_id_manuel: ['']
    });
  }

  initpronosticForm() {
    this.pronosticForm = this.formBuilder.group({
      id_quinte: [''],
      q_id_manuel: ['', Validators.required],
      q_jour: [''],
      q_date: ['', Validators.required],
      q_hyppodrome: ['', Validators.required],
      q_reunion: ['', Validators.required],
      q_course: ['', Validators.required],
      q_distance: ['', Validators.required],
      q_type: ['', Validators.required],
      q_nbre_partant: ['', Validators.required],
      q_prix: ['', Validators.required],
      q_depart: ['', Validators.required],
      q_terrain: ['', Validators.required],
      q_zf1: [''],
      q_zf2: [''],
      q_zr1: [''],
      q_zr2: [''],
      q_zr3: [''],
      q_lienschema: [''],
      q_message: [''],
      q_zr4: [''],
      q_afficheresult: [''],
      q_zr5: [''],
      q_zr6: [''],
      q_zd1: [''],
      q_zd2: [''],
      q_zd3: [''],
      q_zre1: [''],
      q_zre2: [''],
      q_zre3: [''],
      q_zre4: [''],
      q_rs1: [''],
      q_rs2: [''],
      q_rs3: [''],
      q_rs4: [''],
      q_rs5: [''],
      q_rs6: [''],
    });
  }

  listeurl() {
    this.formulaireService.allurl().subscribe(ret => {
      this.rep = ret;
      this.urllist = this.rep.results;
      this.dataSourceurl.data = this.rep.results;
    });
  }


  loadlisteCheveaux(id) {
    this.formulaireService.readallcheveauparid(id).subscribe(ret => {
      this.rep = ret;
      this.listeChevaux = this.rep.results;
      this.dataSourceCheveau.data = this.rep.results;
    });
  }

  affichecheveauForm(id) {
    this.formulaireService.readonecheval(id).subscribe(ret => {
      this.cheveauForm.controls['id_chevaux'].setValue(ret.results[0].id_chevaux);
      this.cheveauForm.controls['ch_numero'].setValue(ret.results[0].ch_numero);
      this.cheveauForm.controls['ch_cheval'].setValue(ret.results[0].ch_cheval);
      this.cheveauForm.controls['ch_sa'].setValue(ret.results[0].ch_sa);
    });
  }

  affichepronosticForm(id) {

    this.affichePronostic = true;
    this.afficheResultat = true;
    this.affichePartant = true;

    this.formulaireService.readonequinte(id).subscribe(ret => {
      this.pronosticForm.controls['id_quinte'].setValue(ret.results[0].id_quinte);
      this.pronosticForm.controls['q_id_manuel'].setValue(ret.results[0].q_id_manuel);
      this.partantForm.controls['partant_id_manuel'].setValue(ret.results[0].q_id_manuel);
      this.pronosticForm.controls['q_jour'].setValue(ret.results[0].q_jour);
      this.q_jour = ret.results[0].q_jour;
      this.pronosticForm.controls['q_date'].setValue(ret.results[0].q_date);
      this.pronosticForm.controls['q_hyppodrome'].setValue(ret.results[0].q_hyppodrome);
      this.pronosticForm.controls['q_reunion'].setValue(ret.results[0].q_reunion);
      this.pronosticForm.controls['q_course'].setValue(ret.results[0].q_course);
      this.pronosticForm.controls['q_distance'].setValue(ret.results[0].q_distance);
      this.pronosticForm.controls['q_type'].setValue(ret.results[0].q_type);
      this.pronosticForm.controls['q_nbre_partant'].setValue(ret.results[0].q_nbre_partant);
      this.pronosticForm.controls['q_prix'].setValue(ret.results[0].q_prix);
      this.pronosticForm.controls['q_depart'].setValue(ret.results[0].q_depart);
      this.pronosticForm.controls['q_terrain'].setValue(ret.results[0].q_terrain);
      this.pronosticForm.controls['q_zf1'].setValue(ret.results[0].q_zf1);
      this.pronosticForm.controls['q_zf2'].setValue(ret.results[0].q_zf2);
      this.pronosticForm.controls['q_zr1'].setValue(ret.results[0].q_zr1);
      this.pronosticForm.controls['q_zr2'].setValue(ret.results[0].q_zr2);
      this.pronosticForm.controls['q_zr3'].setValue(ret.results[0].q_zr3);
      this.pronosticForm.controls['q_zr4'].setValue(ret.results[0].q_zr4);
      this.pronosticForm.controls['q_zr5'].setValue(ret.results[0].q_zr5);
      this.pronosticForm.controls['q_zr6'].setValue(ret.results[0].q_zr6);
      this.pronosticForm.controls['q_zd1'].setValue(ret.results[0].q_zd1);
      this.pronosticForm.controls['q_zd2'].setValue(ret.results[0].q_zd2);
      this.pronosticForm.controls['q_zd3'].setValue(ret.results[0].q_zd3);
      this.pronosticForm.controls['q_zre1'].setValue(ret.results[0].q_zre1);
      this.pronosticForm.controls['q_zre2'].setValue(ret.results[0].q_zre2);
      this.pronosticForm.controls['q_zre3'].setValue(ret.results[0].q_zre3);
      this.pronosticForm.controls['q_zre4'].setValue(ret.results[0].q_zre4);
      this.pronosticForm.controls['q_rs1'].setValue(ret.results[0].q_rs1);
      this.pronosticForm.controls['q_rs2'].setValue(ret.results[0].q_rs2);
      this.pronosticForm.controls['q_rs3'].setValue(ret.results[0].q_rs3);
      this.pronosticForm.controls['q_rs4'].setValue(ret.results[0].q_rs4);
      this.pronosticForm.controls['q_rs5'].setValue(ret.results[0].q_rs5);
      this.pronosticForm.controls['q_rs6'].setValue(ret.results[0].q_rs6);
      this.pronosticForm.controls['q_lienschema'].setValue('');
      this.pronosticForm.controls['q_message'].setValue(ret.results[0].q_message);
      this.pronosticForm.controls['q_afficheresult'].setValue(ret.results[0].q_afficheresult);

      this.loadlisteCheveaux(ret.results[0].q_id_manuel);
    });

  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const fileannx = event.target.files[0];
      this.pronosticForm.get('q_lienschema').setValue(fileannx);
    }
  }

  onFileSelectpartant(event) {
    if (event.target.files.length > 0) {
      const fileannx = event.target.files[0];
      this.partantForm.get('partant_fichierexcel').setValue(fileannx);
    }
  }

  onSubmitPartant(f) {
    this.isLoadingPartant = true;
    const paratantFormData = new FormData();
    paratantFormData.append('partant_id_manuel', f.partant_id_manuel);
    paratantFormData.append('partant_data', JSON.stringify(this.data));
    paratantFormData.append('partant_course', this.q_jour);

    if (this.q_jour == "COURSE D'ATTELE") {
      this.formulaireService.ajoutatelle(paratantFormData).subscribe(ret => {
        this.reponse = ret;
        if (this.reponse.success == true) {
          this.toastr.success(this.reponse.message);
          this.isLoadingPartant = false;
          this.data = [];
          this.data.length = 0;
          this.loadlisteCheveaux(f.partant_id_manuel);
        } else {
          this.toastr.error(this.reponse.message);
          this.isLoadingPartant = false;
        }
      });
    } else
      if (this.q_jour == "COURSE DE MONTE") {
        this.formulaireService.ajoutmonte(paratantFormData).subscribe(ret => {
          this.reponse = ret;
          if (this.reponse.success == true) {
            this.toastr.success(this.reponse.message);
            this.isLoadingPartant = false;
            this.data = [];
            this.data.length = 0;
            this.loadlisteCheveaux(f.partant_id_manuel);
          } else {
            this.toastr.error(this.reponse.message);
            this.isLoadingPartant = false;
          }
        });
      } else
        if (this.q_jour == "COURSE DE PLAT") {
          this.formulaireService.ajoutplat(paratantFormData).subscribe(ret => {
            this.reponse = ret;
            if (this.reponse.success == true) {
              this.toastr.success(this.reponse.message);
              this.isLoadingPartant = false;
              this.data = [];
              this.data.length = 0;
              this.loadlisteCheveaux(f.partant_id_manuel);
            } else {
              this.toastr.error(this.reponse.message);
              this.isLoadingPartant = false;
            }
          });
        } else
          if (this.q_jour == "COURSE DE STEEPLE CHASE") {
            this.formulaireService.ajoutchase(paratantFormData).subscribe(ret => {
              this.reponse = ret;
              if (this.reponse.success == true) {
                this.toastr.success(this.reponse.message);
                this.isLoadingPartant = false;
                this.data = [];
                this.data.length = 0;
                this.loadlisteCheveaux(f.partant_id_manuel);
              } else {
                this.toastr.error(this.reponse.message);
                this.isLoadingPartant = false;
              }
            });
          } else {
            alert("Verifiez le type de course");
          }


    /*  const paratantFormData = new FormData();
      paratantFormData.append('partant_id_manuel', f.partant_id_manuel);
      paratantFormData.append('partant_fichierexcel', this.partantForm.get('partant_fichierexcel').value);
      paratantFormData.append('partant_course',this.q_jour);
      this.formulaireService.createcheval(paratantFormData).subscribe(ret=>{
        this.reponse = ret;
        if (this.reponse.success == true){
          this.toastr.success(this.reponse.message);
          this.isLoadingPartant= false;
        }else{
          this.toastr.error(this.reponse.message);
          this.isLoadingPartant= false;
        }
      });*/
  }

  ajouturl(f) {
    this.isLoadingurl = false;
    const urlFormData = new FormData();
    urlFormData.append('param_lienbaniere1', f.param_lienbaniere1);
    this.formulaireService.createurl(urlFormData).subscribe(ret => {
      this.rep = ret;
      if (this.rep.success == true) {
        this.toastr.success(this.rep.message);
        this.isLoadingurl = true;
        this.parametreformuleForm.reset();
        this.listeurl();
        this.raffraihiListe();
      } else {
        this.toastr.error(this.rep.message);
        this.isLoadingurl = true;
      }
    });
  }
  /*
    onsubmitchevalForm(f) {
      this.isLoadingcheval = false;
      const cheveauFormData = new FormData();
      cheveauFormData.append('id_chevaux', f.id_chevaux);
      cheveauFormData.append('ch_numero', f.ch_numero);
      cheveauFormData.append('ch_cheval', f.ch_cheval);
      cheveauFormData.append('ch_sa', f.ch_sa);
      cheveauFormData.append('ch_q_id_manuel', this.pronosticForm.get('q_id_manuel').value);
      if (!f.id_chevaux) {
        this.formulaireService.createcheval(cheveauFormData).subscribe(ret => {
          this.rep = ret;
          if (this.rep.success == true) {
            this.toastr.success(this.rep.message);
            this.isLoadingcheval = true;
            this.loadlisteCheveaux(this.pronosticForm.get('q_id_manuel').value);
            this.cheveauForm.reset();
            this.raffraihiListe();
          } else {
            this.toastr.error(this.rep.message);
            this.isLoadingcheval = true;
          }
        });
      } else {
        this.formulaireService.updatecheval(cheveauFormData).subscribe(ret => {
          this.rep = ret;
          if (this.rep.success == true) {
            this.toastr.success(this.rep.message);
            this.isLoadingcheval = true;
            this.loadlisteCheveaux(this.pronosticForm.get('q_id_manuel').value);
            this.cheveauForm.reset();
            this.raffraihiListe();
          } else {
            this.toastr.error(this.rep.message);
            this.isLoadingcheval = true;
          }
        });
      }
    }
  */
  onsubmitquinteForm(f) {
    this.isLoadingquinte = false;
    const pronosticFormData = new FormData();
    pronosticFormData.append('id_quinte', f.id_quinte);
    pronosticFormData.append('q_id_manuel', f.q_id_manuel);
    pronosticFormData.append('q_jour', f.q_jour);
    pronosticFormData.append('q_date', f.q_date);
    pronosticFormData.append('q_hyppodrome', f.q_hyppodrome);
    pronosticFormData.append('q_reunion', f.q_reunion);
    pronosticFormData.append('q_course', f.q_course);
    pronosticFormData.append('q_distance', f.q_distance);
    pronosticFormData.append('q_type', f.q_type);
    pronosticFormData.append('q_nbre_partant', f.q_nbre_partant);
    pronosticFormData.append('q_prix', f.q_prix);
    pronosticFormData.append('q_depart', f.q_depart);
    pronosticFormData.append('q_terrain', f.q_terrain);
    pronosticFormData.append('q_grandesurf', f.q_grandesurf);
    pronosticFormData.append('q_zoneforce', f.q_zoneforce);
    pronosticFormData.append('q_zoneresur', f.q_zoneresur);
    pronosticFormData.append('q_zonerouge', f.q_zonerouge);
    pronosticFormData.append('q_basequinte', f.q_basequinte);
    pronosticFormData.append('q_lienschema', this.pronosticForm.get('q_lienschema').value);
    pronosticFormData.append('q_message', f.q_message);
    pronosticFormData.append('q_resultat', f.q_resultat);
    pronosticFormData.append('q_afficheresult', f.q_afficheresult);

    if (!f.id_quinte) {
      this.formulaireService.createquinte(pronosticFormData).subscribe(ret => {
        this.rep = ret;
        if (this.rep.success == true) {
          this.toastr.success(this.rep.message);
          this.affichePronostic = true;
          this.pronosticForm.get('id_quinte').setValue(this.rep.results);
          this.pronosticForm.controls['q_message'].setValue("PRONOSTIC DISPONIBLE  01h00 - 2H00  AVANT LA COURSE");
          this.isLoadingquinte = true;
          this.raffraihiListe();
        } else {
          this.toastr.error(this.rep.message);
          this.isLoadingquinte = true;
        }
      });
    } else {
      this.formulaireService.updatequinte(pronosticFormData).subscribe(ret => {
        this.rep = ret;
        if (this.rep.success == true) {
          this.toastr.success(this.rep.message);
          this.isLoadingquinte = true;
          this.raffraihiListe();
        } else {
          this.toastr.error(this.rep.message);
          this.isLoadingquinte = true;
        }
      });
    }
    this.listeQuintePronostic();
  }

  onsubmitpronosticForm(f) {
    this.isLoadingPronostic = false;
    const pronosticFormData = new FormData();
    pronosticFormData.append('id_quinte', f.id_quinte);
    pronosticFormData.append('q_id_manuel', f.q_id_manuel);
    // pronosticFormData.append('q_jour', f.q_jour);
    pronosticFormData.append('q_date', f.q_date);
    pronosticFormData.append('q_hyppodrome', f.q_hyppodrome);
    pronosticFormData.append('q_reunion', f.q_reunion);
    pronosticFormData.append('q_course', f.q_course);
    pronosticFormData.append('q_distance', f.q_distance);
    pronosticFormData.append('q_type', f.q_type);
    pronosticFormData.append('q_nbre_partant', f.q_nbre_partant);
    pronosticFormData.append('q_prix', f.q_prix);
    pronosticFormData.append('q_depart', f.q_depart);
    pronosticFormData.append('q_terrain', f.q_terrain);
    pronosticFormData.append('q_zf1', f.q_zf1);
    pronosticFormData.append('q_zf2', f.q_zf2);
    pronosticFormData.append('q_zr1', f.q_zr1);
    pronosticFormData.append('q_zr2', f.q_zr2);
    pronosticFormData.append('q_zr3', f.q_zr3);
    pronosticFormData.append('q_zr4', f.q_zr4);
    pronosticFormData.append('q_zr5', f.q_zr5);
    pronosticFormData.append('q_zr6', f.q_zr6);
    pronosticFormData.append('q_zd1', f.q_zd1);
    pronosticFormData.append('q_zd2', f.q_zd2);
    pronosticFormData.append('q_zd3', f.q_zd3);
    pronosticFormData.append('q_zre1', f.q_zre1);
    pronosticFormData.append('q_zre2', f.q_zre2);
    pronosticFormData.append('q_zre3', f.q_zre3);
    pronosticFormData.append('q_zre4', f.q_zre4);
    pronosticFormData.append('q_rs1', f.q_rs1);
    pronosticFormData.append('q_rs2', f.q_rs2);
    pronosticFormData.append('q_rs3', f.q_rs3);
    pronosticFormData.append('q_rs4', f.q_rs4);
    pronosticFormData.append('q_rs5', f.q_rs5);
    pronosticFormData.append('q_rs6', f.q_rs6);
    pronosticFormData.append('q_lienschema', this.pronosticForm.get('q_lienschema').value);
    pronosticFormData.append('q_message', f.q_message);
    // pronosticFormData.append('q_resultat', f.q_resultat);
    pronosticFormData.append('q_afficheresult', f.q_afficheresult);

    this.formulaireService.updatepronostic(pronosticFormData).subscribe(ret => {
      this.rep = ret;
      if (this.rep.success == true) {
        this.toastr.success(this.rep.message);
        this.isLoadingPronostic = true;
        this.affichePartant = true;
        this.raffraihiListe();
      } else {
        this.toastr.error(this.rep.message);
        this.isLoadingPronostic = true;
      }
    });

  }

  onsubmitresultatForm(f) {
    this.isLoadingResultat = false;
    const pronosticFormData = new FormData();
    pronosticFormData.append('id_quinte', f.id_quinte);
    pronosticFormData.append('q_id_manuel', f.q_id_manuel);
    //pronosticFormData.append('q_jour', f.q_jour);
    pronosticFormData.append('q_date', f.q_date);
    pronosticFormData.append('q_hyppodrome', f.q_hyppodrome);
    pronosticFormData.append('q_reunion', f.q_reunion);
    pronosticFormData.append('q_course', f.q_course);
    pronosticFormData.append('q_distance', f.q_distance);
    pronosticFormData.append('q_type', f.q_type);
    pronosticFormData.append('q_nbre_partant', f.q_nbre_partant);
    pronosticFormData.append('q_prix', f.q_prix);
    pronosticFormData.append('q_depart', f.q_depart);
    pronosticFormData.append('q_terrain', f.q_terrain);
    pronosticFormData.append('q_grandesurf', f.q_grandesurf);
    pronosticFormData.append('q_zoneforce', f.q_zoneforce);
    pronosticFormData.append('q_zoneresur', f.q_zoneresur);
    pronosticFormData.append('q_zonerouge', f.q_zonerouge);
    pronosticFormData.append('q_basequinte', f.q_basequinte);
    pronosticFormData.append('q_lienschema', this.pronosticForm.get('q_lienschema').value);
    pronosticFormData.append('q_message', f.q_message);
    //pronosticFormData.append('q_resultat', f.q_resultat);
    pronosticFormData.append('q_afficheresult', f.q_afficheresult);

    this.formulaireService.updateresultat(pronosticFormData).subscribe(ret => {
      this.rep = ret;
      if (this.rep.success == true) {
        this.toastr.success(this.rep.message);
        this.isLoadingResultat = true;
        this.raffraihiListe();
      } else {
        this.toastr.error(this.rep.message);
        this.isLoadingResultat = true;
      }
    });

  }

  deconnexion() {
    localStorage.removeItem('isConnected');
    localStorage.removeItem('jwt');
    localStorage.removeItem('email');
    localStorage.removeItem('nomprenoms');
    localStorage.removeItem('id');
    this.router.navigateByUrl('/auth');
  }

  loadlisteFormulaire() {
    this.formulaireService.readallFormules().subscribe(ret => {
      this.rep = ret;
      this.listeFormule = this.rep.results;

      /*  this.listeFormule.forEach(element => {
          this.initparametreForm();
          this.parametreformuleForm.controls['f_designation'].setValue(element.f_designation);
          console.log(element);
   
        });*/
    });
  }

  listeQuintePronostic() {
    this.formulaireService.readallquintepronostic().subscribe(ret => {
      //this.rep = ret;
      this.listePronostic = ret.results;
      this.dataSourceQuintePronostic.data = ret.results;
    });
  }

  listeClientPronostic() {
    this.clientService.readallClient().subscribe(ret => {
      this.rep = ret
      this.listeClient = this.rep.results;
      this.dataSource.data = this.listeClient;
    }, () => {
      this.toastr.error('Problème de connexion !');
    });

  }


  questionSupprimeurl(id) {
    this.formulaireService.supurl(id).subscribe(ret => {
      this.rep = ret;
      if (this.rep.success == true) {
        this.toastr.success(this.rep.message);
        this.isLoadingurl = true;
        this.listeurl();
      } else {
        this.toastr.success(this.rep.message);
        this.isLoadingurl = true;
      }
    });
  }

  questionSupprime(id: any) {
    this.IDSuppression = id;
  }

  supprimeonecheval() {
    this.formulaireService.suponecheval(this.IDSuppression).subscribe(ret => {
      this.rep = ret;
      if (this.rep.success == true) {
        this.toastr.success(this.rep.message);
        this.isLoadingcheval = true;
        //this.listeQuintePronostic();
      } else {
        this.toastr.success(this.rep.message);
        this.isLoadingcheval = true;
      }
    });
  }

  supprimeonequinte() {
    this.formulaireService.suponequintepronostic(this.IDSuppression).subscribe(ret => {
      this.rep = ret;
      if (this.rep.success == true) {
        this.toastr.success(this.rep.message);
        this.isLoadingResults = true;
        this.listeQuintePronostic();
      } else {
        this.toastr.success(this.rep.message);
        this.isLoadingResults = true;
      }
    });
  }

  supprime() {
    this.isLoadingResults = false;
    this.clientService.deleteClient(this.IDSuppression).subscribe(ret => {
      this.rep = ret;
      if (this.rep.success == true) {
        this.toastr.success(this.rep.message);
        this.isLoadingResults = true;
        this.listeClientPronostic();
      } else {
        this.toastr.success(this.rep.message);
        this.isLoadingResults = true;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilterQuintePronostic(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceQuintePronostic.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceQuintePronostic.paginator) {
      this.dataSourceQuintePronostic.paginator.firstPage();
    }
  }
}
