<body class="hold-transition register-page" [@simpleFadeAnimation]="'in'" style="background-image: url('assets/img/6-bgImages-homePage.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center;">
    <div class="register-box">

        <div class="card card-outline card-danger" style="opacity:0.85;">


            <div class="card-body register-card-body">
                <div class="row">

                    <div class="col-4">
                        <img src="assets/img/icon.png" alt="PMU ROUGE Logo">
                    </div>
                    <div class="col-8">
                        <h2 style="text-align: left; color:red">Mot de passe oublié </h2>
                    </div>
                </div>
                <hr style="margin-top: -10px;">

                <!-- loader  -->
                <div class="spinner4" *ngIf="isLoadingResults">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
                <!-- loader end -->

                <form [formGroup]="mdpoubliFormGroup" (ngSubmit)="envoyer(mdpoubliFormGroup.value)">

                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="Entrez votre Email pour recevoir votre mot de passe" formControlName='Util_Email' required>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>


                    <div class="row">

                        <!-- /.col -->
                        <div class="col-12">
                            <button type="submit" [disabled]='!mdpoubliFormGroup.valid' class="btn btn-primary btn-block btn-flat">Envoyer</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>

            </div>
            <div class="card-footer">
                <p class="mt-2">
                    <a routerLink="/auth" class="text-center">J'ai déjà un compte</a>
                </p>
            </div>
            <!-- /.form-box -->
        </div>
        <!-- /.card -->
    </div>
    <!-- /.register-box -->

</body>