import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MdpoublieComponent } from './mdpoublie/mdpoublie.component';
import { RegisterComponent } from './register/register.component';
import { EnregistrementComponent } from './enregistrement/enregistrement.component';
import {
  EnregistrementabonnementComponent
} from './enregistrementabonnement/enregistrementabonnement.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'mdpoublie', component: MdpoublieComponent },
  { path: 'enregistrement/:id', component: EnregistrementComponent },
  { path: 'abonnement', component: EnregistrementabonnementComponent },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
