<body class="hold-transition " [@simpleFadeAnimation]="'in'" style="background-color: transparent;">
    <div class="container">
        <div class="row" style="background-color: #dfdede; opacity:0.8; padding-bottom:20px">
            <div class=" col-md-6">
                <img src="assets/img/logo-pmu-rouge-comingSoon.png" alt="PMU_ROUGE Logo"
                    style="width:200px; padding-top:20px;">
            </div>
            <div class="col-md-6">
                <span class=" float-right" style="padding-top:50px; font-size:medium; color:rgb(41, 40, 40)">Vendredi 26
                    Février 2021</span>
                <span class="float-right" style="margin-top: 05px; cursor: pointer; font-size:xx-small">
                    <button routerLink='/auth' type="button" class="btn btn-block btn-success btn-xs">J'ai déjà un
                        compte</button>
                </span>
            </div>



        </div>

        <div class="row callout" style="background-color: rgb(245, 246, 246); ">
            <span class=" float-center" style="font-weight: 500; font-size:large; text-align: center;">POUR GAGNER PLUS
                SOUVENT AUX COURSES AVEC PMU ROUGE PREMIUM</span>
        </div>

    </div>
    <!--div class="container">
        <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-right" style="margin-top: 05px; cursor: pointer; font-size:xx-small"> 
                 <button routerLink='/auth' type="button" class="btn btn-block btn-outline-success btn-xs">J'ai déjà un compte</button>
            </span>
        </div>

    </div-->
    <div class="packs">
        <a routerLink="/auth/enregistrement/1" class='pack journee card card-outline card-info'>
            <div class='titre'>1 SEMAINE</div>
            <div class='phrase'>L'ensemble des services premium PMU ROUGE sur une semaine</div>
            <div class='tarif'>8.00<span class='euro'>€</span><span class='parmois'>&nbsp;</span></div>
            <div class='exp'>POUR NOUS DECOUVRIR</div>
            <div routerLink="/auth/enregistrement" class='btn btnAbo btnAboFull'>Acheter</div>
        </a>

        <a routerLink="/auth/enregistrement/2" class='pack journee card card-outline card-info'>
            <div class='titre'>1 MOIS</div>
            <div class='phrase'>Vous pouvez stopper et reprendre votre abonnement quand vous le souhaitez.</div>
            <div class='tarif'>10.00<span class='euro'>€</span><span class='parmois'>par mois</span></div>
            <div class='exp'>45% D'ECONOMIE</div>
            <div routerLink="/auth/enregistrement" class='btn btnAbo btnAboFull'>S'abonner</div>
        </a>
        <a routerLink="/auth/enregistrement/3" class='pack journee card card-outline card-info'>
            <div class='titre'>3 MOIS</div>
            <div class='phrase'>Vous pouvez stopper et reprendre votre abonnement quand vous le souhaitez.</div>
            <div class='tarif'>27.00<span class='euro'>€</span><span class='parmois'>par mois</span></div>
            <div class='exp'>56% D'ECONOMIE</div>
            <div routerLink="/auth/enregistrement" class='btn btnAbo btnAboFull'>S'abonner</div>
        </a>
        <a routerLink="/auth/enregistrement/4" class='pack journee card card-outline card-info'>
            <div class='titre'>ENTERPRISE </div>
            <div class='phrase'>Vous pouvez stopper et reprendre votre abonnement quand vous le souhaitez.</div>
            <div class='tarif'>105.00<span class='euro'>€</span><span class='parmois'>par mois</span></div>
            <div class='exp'>45% D'ECONOMIE</div>
            <div routerLink="/auth/enregistrement" class='btn btnAbo btnAboFull'>S'abonner</div>
        </a>
    </div>
</body>