import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { FooterComponent } from './footer/footer.component';
import { ListeutilisateursComponent } from './listeutilisateurs/listeutilisateurs.component';
import { PageclientComponent } from './pageclient/pageclient.component';
import { TableaudebordComponent } from './tableaudebord/tableaudebord.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';


const routes: Routes = [
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  {
    path: 'admin', component: AdminComponent, children: [
      { path: '', redirectTo: 'monespace', pathMatch: 'full' },
      { path: 'monespace', component: TableaudebordComponent },
      { path: 'footer', component: FooterComponent },
      { path: 'listeutilisateurs', component: ListeutilisateursComponent },
      { path: 'utilisateur/:id', component: UtilisateurComponent },
      { path: 'client/:id', component: PageclientComponent }
    ]
  },
  { path: '**', redirectTo: 'admin' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
