import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';
import { animate, state, style, transition, trigger } from '@angular/animations';



@Component({
  selector: 'app-enregistrement',
  templateUrl: './enregistrement.component.html',
  styleUrls: ['./enregistrement.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class EnregistrementComponent implements OnInit {

  clientForm: FormGroup;
  isLoadingResults = false;

  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.isLoadingResults = false;
    this.clientForm = this.formBuilder.group({
      cl_nomprenoms: ['', Validators.required],
      cl_ville: ['', Validators.required],
      cl_adresse: [''],
      cl_datenaissance: [''],
      cl_codepostal: [''],
      cl_email: ['', Validators.email],
      cl_telephone: ['', Validators.required],
      cl_idformule: [this.route.snapshot.paramMap.get('id')],
      cl_idutilisateur: [''],
      cl_mdp: ['', Validators.required],
      cl_mdpConf: ['', Validators.required]
    });
  }

  onSubmitForm(f) {
    if (f.cl_mdp != f.cl_mdpConf) {
      alert('Les mots de passe sont différents');
      return;
    }

    this.isLoadingResults = true;
    const clientFormData = new FormData();
    clientFormData.append('cl_id', f.cl_id);
    clientFormData.append('cl_nomprenoms', f.cl_nomprenoms);
    clientFormData.append('cl_ville', f.cl_ville);
    clientFormData.append('cl_adresse', f.cl_adresse);
    clientFormData.append('cl_datenaissance', f.cl_datenaissance);
    clientFormData.append('cl_codepostal', f.cl_codepostal);
    clientFormData.append('cl_email', f.cl_email);
    clientFormData.append('cl_telephone', f.cl_telephone);
    clientFormData.append('cl_idformule', f.cl_idformule);
    clientFormData.append('cl_dateexpireformule', f.cl_dateexpireformule);
    clientFormData.append('cl_actif', 'false');
    clientFormData.append('cl_bloque', 'true');
    clientFormData.append('cl_idutilisateur', '0');
    clientFormData.append('cl_mdp', f.cl_mdp);

    this.clientService.createClient(clientFormData).subscribe(result => {
      if (result.success == true) {
        this.toastr.success(result.message, 'PMU ROUGE ENREGISTREMENT');
        this.reinitform();
        this.isLoadingResults = false;
        this.router.navigate(['https://app.pmurouge.com/']);
      } else {
        this.toastr.error(result.message, 'PMU ROUGE');
        this.isLoadingResults = false;
      }
    }, () => {
      this.toastr.error('Problème de connexion', 'PMU ROUGE');
      this.isLoadingResults = false;
    });
  }

  reinitform() {
    this.clientForm.reset();
  }

  retour() {
    this.router.navigate(['/register']);
  }
}
