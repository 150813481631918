<div class="wrapper" [@simpleFadeAnimation]="'in'">


    <!-- Content Wrapper. Contains page content -->
    <div style="background-color: rgb(248, 249, 249); background-image: url('assets/img/6-bgImages-homePage.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center;">
        <!-- Main content -->
        <div class="container">

            <div class="row" style="background-color: #dfdede; opacity:0.8; padding-bottom:20px">
                <div class="col-md-6 col-sd-12 col-xs-12">
                    <img src="assets/img/logo-pmu-rouge-comingSoon.png" alt="PMU_ROUGE Logo" style="width:200px; padding-top:20px;">
                </div>
                <div class="col-md-6 col-sd-12 col-xs-12">
                    <!--span class=" float-right" style="padding-top:50px; font-size:medium; color:rgb(41, 40, 40)">
                        {{datedujour | date:"dd/MM/yyyy"}} 
                        </span-->
                </div>
            </div>

            <div class="row callout" style="background-color: rgb(245, 246, 246); ">

                <div class="col-12">
                    <span style="color: green; font-size: large;">BIENVENUE : {{nomprenoms}}</span>
                    <br>
                    <span style="color: rgb(206, 8, 8); font-size: large;">{{datedujour | date:"dd/MM/yyyy"}} </span>
                    <span class="btn btn-danger float-right" (click)="deconnexion()">Deconnexion</span>
                </div>

            </div>

            <router-outlet></router-outlet>
        </div>
        <!-- /.container-fluid -->
        <!-- /.content -->
    </div>

</div>