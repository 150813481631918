import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enregistrementabonnement',
  templateUrl: './enregistrementabonnement.component.html',
  styleUrls: ['./enregistrementabonnement.component.scss']
})
export class EnregistrementabonnementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
