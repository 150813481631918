import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class UtilisateurComponent implements OnInit {

  userForm: FormGroup;
  isLoadingResults = true;
  OneUser: any = [];
  id: string;
  listeDirection: any = [];
  direction: any = [];
  afficheReinit = false;
  reponse: any;

  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private usersservice: UserService) { }

  ngOnInit() {
    this.initForm();
    this.loadOneService();
    if (this.id === '0') {
      this.afficheReinit = false;
    } else {
      this.afficheReinit = true;
    }
  }



  loadOneService() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id !== '0') {
      this.usersservice.readoneUtilisateur(this.id).subscribe(reponse => {
        this.OneUser = reponse;
        this.userForm.controls['Id_Utilisateur'].setValue(this.OneUser.results.liste[0].Id_Utilisateur);
        this.userForm.controls['Util_Nomprenoms'].setValue(this.OneUser.results.liste[0].Util_Nomprenoms);
        this.userForm.controls['Util_Email'].setValue(this.OneUser.results.liste[0].Util_Email);
        if (this.OneUser.results.liste[0].Util_tech !== 'true') {
          this.userForm.controls['Util_tech'].setValue(false);
        } else {
          this.userForm.controls['Util_tech'].setValue(true);
        }
        this.userForm.controls['Util_Direction'].setValue(this.OneUser.results.liste[0].Util_Direction);
        this.userForm.controls['Util_telephone'].setValue(this.OneUser.results.liste[0].Util_telephone);
        this.userForm.controls['Util_Poste'].setValue(this.OneUser.results.liste[0].Util_Poste);

      });

    }
  }

  initForm() {
    this.isLoadingResults = false;
    this.userForm = this.formBuilder.group({
      Id_Utilisateur: [''],
      Util_Nomprenoms: ['', Validators.required],
      Util_Email: ['', Validators.required],
      Util_tech: [''],
      Util_Direction: [''],
      Util_telephone: [''],
      Util_Poste: [''],
      Util_IDDirection: ['']
    });
  }

  onSubmitForm(f) {
    this.isLoadingResults = true;
    const userFormData = new FormData();
    userFormData.append('Util_Nomprenoms', f.Util_Nomprenoms);
    userFormData.append('Util_Email', f.Util_Email);
    userFormData.append('Util_tech', f.Util_tech);
    userFormData.append('Util_Direction', f.Util_Direction);
    userFormData.append('Util_telephone', f.Util_telephone);
    userFormData.append('Util_Poste', f.Util_Poste);
    userFormData.append('Util_IDDirection', '0');

    if (this.id === '0') {
      this.usersservice.creatUtilisateur(userFormData).subscribe(result => {
        switch (result.success) {
          case true: {
            // statements;
            this.toastr.success(result.message);
            this.reinitform();
            break;
          }
          case false: {
            // statements;
            this.toastr.error(result.message);
            break;
          }
          default: {
            // statements;
            this.toastr.error('Erreur de connexion au serveur');
            break;
          }

        }
        this.isLoadingResults = false;

      }, () => {
        this.toastr.error('Erreur de connexion au serveur');
        this.isLoadingResults = false;
      });
    } else {
      userFormData.append('Id_Utilisateur', f.Id_Utilisateur);
      this.usersservice.updateUtilisateur(userFormData).subscribe(result => {
        switch (result.success) {
          case true: {
            // statements;
            this.toastr.success(result.message);
            break;
          }
          case false: {
            // statements;
            this.toastr.error(result.message);
            break;
          }
          default: {
            // statements;
            this.toastr.error('Erreur de connexion au serveur');
            break;
          }

        }
        this.isLoadingResults = false;
        this.location.back();
        //this.router.navigate(['/listeutilisateur']);
      }, () => {
        this.toastr.error('Erreur de connexion au serveur');
        this.isLoadingResults = false;
      });
    }

  }

  reinitform() {
    this.userForm.controls['Id_Utilisateur'].reset();
    this.userForm.controls['Util_Nomprenoms'].reset();
    this.userForm.controls['Util_Email'].reset();
    this.userForm.controls['Util_tech'].reset();
    this.userForm.controls['Util_Direction'].reset();
    this.userForm.controls['Util_telephone'].reset();
    this.userForm.controls['Util_Poste'].reset();
    this.userForm.controls['Util_IDDirection'].reset();
  }

  reinitmdp(f) {
    this.isLoadingResults = true;
    const userData = new FormData();
    userData.append('Util_Email', f.Util_Email);
    userData.append('Id_Utilisateur', f.Id_Utilisateur);
    this.usersservice.reinitmdp(userData).subscribe(result => {
      this.reponse = result;
      switch (this.reponse.success) {
        case true: {
          // statements;
          this.toastr.success(this.reponse.message);
          this.isLoadingResults = false;
          break;
        }
        case false: {
          // statements;
          this.toastr.error(this.reponse.message);
          this.isLoadingResults = false;
          break;
        }
        default: {
          // statements;
          this.toastr.error('Erreur de connexion au serveur');
          this.isLoadingResults = false;
          break;
        }

      }
    }, () => {
      // erreur
      this.toastr.error('Erreur de connexion au serveur');
      this.isLoadingResults = false;
    });
  }
}