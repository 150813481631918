import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { TableaudebordComponent } from './tableaudebord/tableaudebord.component';
import { FooterComponent } from './footer/footer.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ListeutilisateursComponent } from './listeutilisateurs/listeutilisateurs.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageclientComponent } from './pageclient/pageclient.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [AdminComponent,
    TableaudebordComponent,
    FooterComponent,
    ListeutilisateursComponent,
    UtilisateurComponent,
    PageclientComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule
  ]
})
export class AdminModule {


}
