import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class ClientService {
  urlG: string;
  urlT: string;
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient) {
    this.urlG = this.configService.urlg;
  }

  readallClient(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/clients/listeclient.php');
  }

  readoneClient(id: any): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/clients/unclient.php?id=' + id);
  }

  createClient(data: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/clients/creationclient.php', data);
  }

  updateClient(data: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/clients/updateclient.php', data);
  }

  deleteClient(id: any): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/clients/suppressionclient.php?cl_id=' + id);
  }

}
