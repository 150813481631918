<div [@simpleFadeAnimation]="'in'">
    <!-- /.col -->
    <div class="row callout">
        <div class="col-12">
            <div class="card">
                <div class="card-header" style="background-color: #8d8485 ;">
                    <ul class="nav nav-pills">
                       
                        <li class="nav-item"><a class="nav-link active" style="color: aliceblue;" href="#pronostics" data-toggle="tab" (click)="appuiPronostic()" >Pronostics</a>
                        </li>

                        <li class="nav-item" *ngIf="estadmin=='true'"><a class="nav-link " style="color: aliceblue;" href="#clients" data-toggle="tab" (click)="appuiClient()">Gestion
                            Clients</a></li>

                        <li class="nav-item"><a class="nav-link" style="color: aliceblue;" href="#parametre" data-toggle="tab" (click)="appuiParametre()">Paramètres</a></li>
                        <li class="nav-item" *ngIf="estadmin=='true'"><a class="nav-link" style="color: aliceblue;" routerLink='/admin/admin/listeutilisateurs' data-toggle="tab">Utilisateurs</a></li>
                   
                   
                    </ul>
                </div>

                <div class="card-body">
                    <div class="tab-content" >
                        
                        <!-- TAB PRONOSTICS -->
                        <div class="active tab-pane" id="pronostics">
                            <div class="card">
                                <div class="card-header "
                                    style="background-color: #555a56; color: #FFFF; height: auto ">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-xs-12" style="margin: auto;">
                                            <h3 class="card-title">LISTE DES COURSES</h3>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12">
                                            <button data-toggle="modal"
                                            data-target="#modal-pronostic" (click)="ouvrenouveauquinte()"
                                            
                                            class="btn btn-primary float-sm-right">Nouvelle course</button>    
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                               
                                    <div class="row">
                                            <mat-form-field>
                                                <mat-label>Rechercher une course</mat-label>
                                                <input matInput (keyup)="applyFilterQuintePronostic($event)"
                                                    placeholder="Entrez une recherche ici..." #input>
                                            </mat-form-field>
                                    </div>
                                    
                        <div class="table-responsive-sm">
                            <table class="table table-bordered table-striped dataTable" 
                            mat-table [dataSource]="dataSourceQuintePronostic" matSort 
                            >
               
                               <!-- ID Column -->
                               <ng-container matColumnDef="date">
                                   <th *matHeaderCellDef mat-sort-header 
                                       style="width: 100px; text-align: center"> <b>Date</b>
                                   </th>
                                   <td *matCellDef="let pronistic"> {{pronistic.q_date | date:"dd/MM/yyyy"}} </td>
                               </ng-container>
                           <!-- Name Column -->
                               <ng-container matColumnDef="depart">
                                   <th *matHeaderCellDef mat-sort-header 
                                       style="width: 100px; text-align: center">
                                       Départ à</th>
                                   <td  *matCellDef="let pronistic" style="text-align: center;">
                                       {{pronistic.q_depart | slice:0:5}}
                                   </td>
                               </ng-container>
               
                               <!-- Progress Column -->
                               <ng-container matColumnDef="course" >
                                   <th *matHeaderCellDef mat-sort-header 
                                       style="width: 100px; text-align: center" > Course N° </th>
                                   <td  *matCellDef="let pronistic" style="text-align: center;">
                                       {{pronistic.q_course}} </td>
                               </ng-container>
               
                             
                               <!-- Name Column -->
                               <ng-container matColumnDef="hypodrome">
                                   <th *matHeaderCellDef mat-sort-header>
                                       Hyppodrome</th>
                                   <td  *matCellDef="let pronistic">
                                       {{pronistic.q_hyppodrome}}
                                   </td>
                               </ng-container>
               
                               <!-- Color Column -->
                               <ng-container matColumnDef="boutons">
                                   <th *matHeaderCellDef mat-sort-header  style="width: 5px;"> </th>
                                   <td  *matCellDef="let pronistic">
                                       <div class="btn-group">
                                           <button type="button"
                                               (click)="affichepronosticForm(pronistic.id_quinte)"
                                               class="btn  btn-primary btn-xs" data-toggle="modal"
                                               data-target="#modal-pronostic">
                                               <i class="fas fa-edit"></i></button>
                                           <button (click)="questionSupprime(pronistic.id_quinte)"
                                               type="button" class="btn  btn-danger btn-xs"
                                               style="margin-left: 5px;" data-toggle="modal"
                                               data-target="#modal-sup_quinte">
                                               <i class="fas fa-trash"></i></button>
               
                                       </div>
                                   </td>
                               </ng-container>
                               <!-- Color Column -->
                               <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                               <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                           </table>
                          
                        </div>
                            <mat-paginator #ppronostic [pageSizeOptions]="[5, 25, 100]"
                           showFirstLastButtons
                           aria-label="Select page"></mat-paginator>

                                </div>
                            </div>
                        </div>

                        <!-- TAB GESTION CLIENTS-->
                        <div class="tab-pane" id="clients">

                            <div class="card-header "
                                    style="background-color: #555a56; color: #FFFF; height: auto ">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-xs-12" style="margin: auto;">
                                            <h3 class="card-title">LISTE DES CLIENTS</h3>
                                        </div>
                                        <button routerLink='/admin/admin/client/0'
                                        class="btn btn-primary float-sm-right">Nouveau Client</button>
                                    </div>
                                </div>


                            <mat-form-field>
                                <mat-label>Rechercher un compte client</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Recherche ici..."
                                    #input>
                            </mat-form-field>

                           

                            <!-- loader  -->
                            <div class="spinner4" *ngIf="!isLoadingResults">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <!-- loader end -->
<div class="table-responsive">
 <table class="table table-bordered table-striped dataTable" mat-table
                                [dataSource]="dataSource" matSort  style="margin-top: 5px; overflow-x:auto;">

                                <!-- ID Column -->
                                <ng-container matColumnDef="nomprenoms">
                                    <th *matHeaderCellDef mat-sort-header  > <b>Nom et Prénoms</b>
                                    </th>
                                    <td  *matCellDef="let client"> {{client.cl_nomprenoms}} </td>
                                </ng-container>

                                <!-- Progress Column -->
                                <ng-container matColumnDef="email">
                                    <th *matHeaderCellDef mat-sort-header > Email </th>
                                    <td  *matCellDef="let client">
                                        {{client.cl_email}} </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="mobile">
                                    <th *matHeaderCellDef mat-sort-header  style="width: 100px; text-align: center">
                                        Mobile</th>
                                    <td  *matCellDef="let client">
                                        {{client.cl_telephone}}
                                    </td>
                                </ng-container>
                                <!-- Name Column -->
                                <ng-container matColumnDef="ville">
                                    <th *matHeaderCellDef mat-sort-header  style="width: 100px; text-align: center">
                                        Ville</th>
                                    <td  *matCellDef="let client">
                                        {{client.cl_ville}}
                                    </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="formule">
                                    <th *matHeaderCellDef mat-sort-header  style="width: 100px; text-align: center">
                                        Formule
                                    </th>
                                    <td  *matCellDef="let client">
                                        {{client.f_designation}}
                                    </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="dateexpire">
                                    <th *matHeaderCellDef mat-sort-header  style="width: 100px; text-align: center">
                                        Expire le
                                    </th>
                                    <td  *matCellDef="let client" style="text-align: center;">
                                        {{client.cl_dateexpireformule | date:'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <th *matHeaderCellDef mat-sort-header  style="width: 100px; text-align: center">
                                        Status
                                    </th>
                                    <td  *matCellDef="let client">
                                        <small *ngIf="client.cl_actif=='true'" class="badge badge-success"><i
                                                class="far fa-clock"></i> Actif</small>
                                        <small *ngIf="client.cl_actif=='false'" class="badge badge-danger"><i
                                                class="far fa-clock"></i> Inactif</small>
                                    </td>
                                </ng-container>


                                <!-- Color Column -->
                                <ng-container matColumnDef="boutons">
                                    <th *matHeaderCellDef mat-sort-header  style="width: 5px;"> </th>
                                    <td  *matCellDef="let client">
                                        <div class="btn-group"> <button
                                                routerLink='/admin/admin/client/{{client.cl_id}}' type="button"
                                                class="btn  btn-primary btn-xs">
                                                <i class="fas fa-edit"></i></button>
                                            <button (click)="questionSupprime(client.cl_id)" type="button"
                                                class="btn  btn-danger btn-xs" style="margin-left: 5px;"
                                                data-toggle="modal" data-target="#modal-info">
                                                <i class="fas fa-trash"></i></button>

                                        </div>
                                    </td>
                                </ng-container>
                                <!-- Color Column -->
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            </table>
</div>
                           

                            <mat-paginator #pclient [pageSizeOptions]="[10, 25, 100]"
                                                            showFirstLastButtons
                                                            aria-label="Select page"></mat-paginator>
                        </div>

                         <!-- TAB PARAMETRE -->
                        <div class="tab-pane" id="parametre">
                            <div class="card-header "
                            style="background-color: #555a56; color: #FFFF; height: auto ">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-xs-12" style="margin: auto;">
                                    <h3 class="card-title">MODIFICATION DES
                                        BANNIERRES DE PUBLICITE</h3>
                                </div>
                            </div>
                        </div>
                   
                            <div class="col-md-12">
                                <!-- general form elements -->
                        
                                    <!-- form start -->
                                    <!-- loader  -->
                                    <div class="spinner4" *ngIf="!isLoadingResults">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                    </div>
                                    <!-- loader end -->
                                    <form [formGroup]="parametreformuleForm">
                                        <div class="card-body">
                                  
                                            <div class="row">
                                                <div class="col-md-10 col-sm-10 col-xs-12">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="param_lienbaniere1"
                                                            placeholder="URL Bannière Pub">
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-sm-2 col-xs-12">
                                                    <div class="form-group">
                                                        <button class="btn btn-success btn-block" type="button"
                                                            [disabled]="parametreformuleForm.invalid"
                                                            (click)="ajouturl(parametreformuleForm.value)">Ajouter</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- loader  -->
                                            <div class="spinner4" *ngIf="!isLoadingurl">
                                                <div class="bounce1"></div>
                                                <div class="bounce2"></div>
                                                <div class="bounce3"></div>
                                            </div>
                                            <!-- loader end -->
                                            <div class="table-responsive">
  <table class="table table-bordered table-striped dataTable" mat-table
                                                [dataSource]="dataSourceurl" matSort style="margin-top: 5px;">
                        
                                                <!-- ID Column -->
                                                <ng-container matColumnDef="url">
                                                    <th *matHeaderCellDef mat-sort-header 
                                                        style="width: 100%; text-align: center"> <b>url des banières
                                                            publicitatire</b>
                                                    </th>
                                                    <td  *matCellDef="let url"> {{url.param_lienbaniere1}} </td>
                                                </ng-container>
                        
                                                <!-- Color Column -->
                                                <ng-container matColumnDef="boutons">
                                                    <th *matHeaderCellDef mat-sort-header  style="text-align: center">
                                                    </th>
                                                    <td  *matCellDef="let url">
                                                        <div class="btn-group">
                        
                                                            <button (click)="questionSupprimeurl(url.param_id)"
                                                                type="button" class="btn  btn-danger btn-xs"
                                                                style="margin-left: 5px;">
                                                                <i class="fas fa-trash"></i></button>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                                <!-- Color Column -->
                                                <tr mat-header-row *matHeaderRowDef="displayedColumnsurl"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumnsurl;">
                                                </tr>
                                            </table>
                                            </div>
                                          
                                            <mat-paginator #purl [pageSizeOptions]="[10, 25, 100]"
                                                            showFirstLastButtons
                                                            aria-label="Select page"></mat-paginator>
                        
                                            <!--div class="col-12">
                                                <div class="form-group">
                                                    <label for="tel">URL Bannière Pub2</label>
                                                    <input type="text" class="form-control"
                                                        formControlName="param_lienbaniere2" placeholder="">
                                                </div>
                                            </div-->
                        
                                            <!--button class="btn btn-primary " style="margin: 5px;">Enregistrer</button>
                        
                                            <h3 style="background-color: #086A87; color:#FFFF">MODIFICATION DES FORMULES
                                                PREMIUM
                                            </h3>
                                            <div class="callout" *ngFor="let formule  of listeFormule">
                                                <h3 style="color: red; background-color:rgb(223, 223, 235);">Formule
                                                    {{formule.f_index}}
                                                </h3>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Désignation </label>
                                                            <input type="text" class="form-control"
                                                                formControlName="id_formule" placeholder="Id" hidden>
                                                            <input type="text" class="form-control"
                                                                formControlName="f_designation"
                                                                style="text-align: center;">
                                                        </div>
                                                    </div>
                                                    <div class="col-8">
                                                        <div class="form-group">
                                                            <label for="poste">Description</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="f_description"
                                                                placeholder="L'ensemble des services premium PMU ROUGE sur une semaine">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="poste">Prix</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="f_prix" style="text-align: center;"
                                                                placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="poste">Periodicité</label>
                                                            <input type="text" class="form-control"
                                                                formControlName="f_periodicite" placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="poste">Offre</label>
                                                            <input type="text" style="text-align: center;"
                                                                class="form-control" formControlName="f_periodicite"
                                                                placeholder="POUR NOUS DECOUVRIR">
                                                        </div>
                                                    </div>
                        
                                                </div>
                        
                                                <button class="btn btn-success " style="margin: 5px;">Modifier</button>
                        
                        
                                            </div-->
                                        </div>
                                        <!-- /.card-body -->
                        
                                        <div class="card-footer">
                        
                                        </div>
                        
                                    </form>
                            
                                <!-- /.card -->
                            </div>
                        
                        </div>
                        
                    </div>
                    <!-- /.tab-content -->
                </div>
            </div>
        </div>

    </div>


    <!-- /.modal question -->
    <div class="modal fade" id="modal-info">
        <div class="modal-dialog">
            <div class="modal-content bg-info">
                <div class="modal-header" style="height: 50px; " >
                    <h3 class="modal-title">PMU ROUGE </h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <p>Voulez-vous supprimer l'enregistrement ?</p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-outline-light" data-dismiss="modal">Annuler</button>
                    <button type="button" class="btn btn-danger" (click)="supprime()"
                        data-dismiss="modal">Supprimer</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
    <!-- /.modal question -->
    <div class="modal fade" id="modal-sup_quinte">
        <div class="modal-dialog">
            <div class="modal-content bg-info">
                <div class="modal-header" style="height: 50px;">
                    <h3 class="modal-title">PMU ROUGE </h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <p>Voulez-vous supprimer la course ?</p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-outline-light" data-dismiss="modal">Annuler</button>
                    <button type="button" class="btn btn-danger" (click)="supprimeonequinte()"
                        data-dismiss="modal">Supprimer</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
    <!-- /.modal question -->
    <div class="modal fade" id="modal-supCheval">
        <div class="modal-dialog">
            <div class="modal-content bg-info">
                <div class="modal-header" style="height: 50px;">
                    <h3 class="modal-title">PMU ROUGE </h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <p>Voulez-vous supprimer le cheval ?</p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-outline-light" data-dismiss="modal">Annuler</button>
                    <button type="button" class="btn btn-danger" (click)="supprimeonecheval()"
                        data-dismiss="modal">Supprimer</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->

    <!--MODAL PRONOSTIC-->
    <div class="modal fade" id="modal-pronostic">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header" style="height: 50px; background-color: #d6414b; color: #FFFF;">
                    <h3 class="modal-title"><b>PMU ROUGE</b> </h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">

                    <div class="col-md-12">
                        <!-- general form elements -->
                        <div class="card card-outline card-info">
                            <!-- form start -->
                            <!-- loader  -->
                            <div class="spinner4" *ngIf="!isLoadingResults">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <!-- loader end -->
                            <form [formGroup]="pronosticForm">
                                <div class="card-body">
                                    <div class="card-header "
                                        style="background-color: #086A87; color: #FFFF;  height: 50px;">
                                        <div class="row">
                                            <div style="margin: auto;">
                                                <h3 class="card-title">ENREGISTREMENT D'UNE COURSE</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <input type="text" class="form-control" placeholder="Id" formControlName="id_quinte"
                                        hidden>
                                    <input type="text" class="form-control" placeholder="IdManuel"
                                        formControlName="q_id_manuel" hidden>

                                    <div class="row" style="margin-top: 20px;">
                                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Type de Course</label>
                                                <ng-select [items]="listecourse" bindLabel="course"
                                                    placeholder="-- Choisissez le Type de la course --" bindValue="course"
                                                    formControlName="q_jour" [(ngModel)]="q_jour">

                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="tel">Date</label>
                                                <input type="date" class="form-control" formControlName="q_date">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="poste">Hyppodrome</label>
                                                <input type="text" class="form-control" placeholder="Hippodrome"
                                                    formControlName="q_hyppodrome">
                                            </div>
                                        </div>
                                    </div>

                            <div class="row">
                                <div class="row col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div class="form-group">
                                            <label>Réunion N° </label>
                                            <input type="text" class="form-control" style="text-align: center;"
                                                placeholder="Réunion" formControlName="q_reunion">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div class="form-group">
                                            <label for="poste">Course N° </label>
                                            <input type="text" class="form-control" style="text-align: center;"
                                                placeholder="" formControlName="q_course">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div class="form-group">
                                            <label for="poste">Distance</label>
                                            <input type="number" class="form-control" style="text-align: center;"
                                                placeholder="" formControlName="q_distance">
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div class="form-group">
                                            <label for="poste">Type</label>
                                            <input type="text" class="form-control" placeholder=""
                                                formControlName="q_type">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div class="form-group">
                                            <label for="poste">Nbre Partants</label>
                                            <input type="number" style="text-align: center;" class="form-control"
                                                placeholder="" formControlName="q_nbre_partant">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div class="form-group">
                                            <label for="poste">Prix</label>
                                            <input type="number" style="text-align: center;" class="form-control"
                                                placeholder="" formControlName="q_prix">
                                        </div>
                                    </div>
                                </div>

                            </div>
                              

                                    <div class="row">
                                        <div class="col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Depart à</label>
                                                <input type="time" class="form-control" placeholder=""
                                                    formControlName="q_depart">
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="tel">Terrain</label>
                                                <input type="text" class="form-control" placeholder=""
                                                    formControlName="q_terrain">
                                            </div>
                                        </div>

                                    </div>


                                    <div class="row col-12" style="margin: 5px;">
                                        <div class="col-md-4 col-sm-2"></div>
                                        <div class="col-md-4 col-sm-8" style="text-align: center;">
                                            <button class="btn btn-success btn-block" type="button"
                                                [disabled]="pronosticForm.invalid"
                                                (click)="onsubmitquinteForm(pronosticForm.value)"
                                                > ENREGISTREZ LA COURSE</button>
                                                <hr>
                                            <!-- loader  -->
                                            <div class="spinner4" *ngIf="!isLoadingquinte">
                                                <div class="bounce1"></div>
                                                <div class="bounce2"></div>
                                                <div class="bounce3"></div>
                                            </div>
                                            <!-- loader end -->
                                        </div>
                                        <div class="col-md-4 col-sm-2"></div>
                                    </div>

                                    <div *ngIf="afficheResultat" [@simpleFadeAnimation]="'in'">
                                
                                        <div class="card-header "
                                        style="background-color: #086A87; color: #FFFF;  height: 50px;">
                                        <div class="row">
                                            <div style="margin: auto;">
                                                <h3 class="card-title">ENREGISTREZ LE RESULTAT POUR CETTE COURSE</h3>
                                            </div>
                                        </div>
                                    </div>            
                                        <div class="row" style="margin-top: 5px;">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Résultat (ARIVEES DU
                                                        JOUR)</label>
                                                        <div class="form-group row" style="color: black; padding: 3px; border: solid; border-color: #055821;">
                                                            <label for="inputEmail3" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-form-label">LEGENDE COULEUR </label>
                                                            <div class="col-lg-1 col-md-1 col-sm-2 col-xs-4">
                                                              <input type="number" class="form-control" id="q_rs1" formControlName="q_rs1" placeholder="1er" style="background-color: #f34f43; color: black; text-align: center;">
                                                            </div>
                                                            <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                                                <input type="number" class="form-control" id="q_rs2" formControlName="q_rs2" placeholder="2ème" style="background-color: #2196F3; color: black; text-align: center; " >
                                                              </div>
                                                              <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                                                <input type="number" class="form-control" id="q_rs3" formControlName="q_rs3" placeholder="3ème" style="background-color: #4CAF50;  color: black; text-align: center;">
                                                              </div>
                                                              <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                                                <input type="number" class="form-control" id="q_rs4" formControlName="q_rs4" placeholder="4ème" style="background-color: #8D6E63;  color: black; text-align: center;">
                                                              </div>
                                                              <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                                                <input type="number" class="form-control" id="q_rs5" formControlName="q_rs5" placeholder="5ème" style="background-color: #757575;  color: black; text-align: center;">
                                                              </div>
                                                              <div class="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                                                <input type="number" class="form-control" id="q_rs6" formControlName="q_rs6" placeholder="6ème" style="background-color: #607D8B;  color: black; text-align: center;">
                                                              </div>
                                                          </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="row col-12" style="margin: 5px;">
                                            <div class="col-md-4 col-sm-2"></div>
                                            <div class="col-md-4 col-sm-8" style="text-align: center;">
                                                <button class="btn btn-success btn-block" type="button"
                                                    (click)="onsubmitpronosticForm(pronosticForm.value)">ENREGISTREZ LE RESULTAT</button>
                                                <hr>
                                                <div class="spinner4" *ngIf="!isLoadingResultat">
                                                    <div class="bounce1"></div>
                                                    <div class="bounce2"></div>
                                                    <div class="bounce3"></div>
                                                </div>
                                                
                                            </div>
                                            <div class="col-md-4 col-sm-2"></div>
                                        </div>
                                    </div>
                                  
                                    <div *ngIf="affichePronostic" [@simpleFadeAnimation]="'in'">
                                        <div class="card-header "
                                        style="background-color: #086A87; color: #FFFF;  height: 50px;">
                                        <div class="row">
                                            <div style="margin: auto;">
                                                <h3 class="card-title"> ENREGISTREZ LE PRONOSTIC DE LA COURSE</h3>
                                            </div>
                                        </div>
                                    </div>
                                       
                                        <div class="card card-outline card-info col-12" style="padding: 10px;">

                                            <div class="form-group row" style="background-color: rgb(223, 233, 253); padding: 3px;">
                                                <label for="inputEmail3" class="col-sm-10 col-form-label">Zone de Force </label>
                                                <div class="col-sm-1">
                                                  <input type="number" class="form-control" id="q_zf1" style="font-weight: 800; font-size: large;" formControlName="q_zf1">
                                                </div>
                                                <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zf2" style="font-weight: 800; font-size: large;"  formControlName="q_zf2">
                                                  </div>
                                              </div>
                                              <div class="form-group row" style="background-color: rgb(204, 220, 255); padding: 3px;">
                                                <label for="inputEmail3" class="col-sm-6 col-form-label">Zone Rouge </label>
                                                <div class="col-sm-1">
                                                  <input type="number" class="form-control" id="q_zr1" style="font-weight: 800; font-size: large;"  formControlName="q_zr1">
                                                </div>
                                                <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zr2" style="font-weight: 800; font-size: large;"  formControlName="q_zr2">
                                                  </div>
                                                  <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zr3" style="font-weight: 800; font-size: large;"  formControlName="q_zr3">
                                                  </div>
                                                  <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zr4" style="font-weight: 800; font-size: large;"  formControlName="q_zr4">
                                                  </div>
                                                  <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zr5" style="font-weight: 800; font-size: large;"  formControlName="q_zr5">
                                                  </div>
                                                  <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zr6" style="font-weight: 800; font-size: large;"  formControlName="q_zr6">
                                                  </div>
                                              </div>
                                              <div class="form-group row" style="background-color: rgb(223, 233, 253); padding: 3px;">
                                                <label for="inputEmail3" class="col-sm-9 col-form-label">Zone de Diamant </label>
                                                <div class="col-sm-1">
                                                  <input type="number" class="form-control" id="q_zd1" style="font-weight: 800; font-size: large;"  formControlName="q_zd1">
                                                </div>
                                                <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zd2" style="font-weight: 800; font-size: large;"  formControlName="q_zd2">
                                                  </div>
                                                  <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zd3" style="font-weight: 800; font-size: large;"  formControlName="q_zd3">
                                                  </div>
                                              </div>
                                              <div class="form-group row" style="background-color: rgb(204, 220, 255); padding: 3px;">
                                                <label for="inputEmail3" class="col-sm-8 col-form-label">Zone de Regurgence </label>
                                                <div class="col-sm-1">
                                                  <input type="number" class="form-control" id="q_zre1" style="font-weight: 800; font-size: large;"  formControlName="q_zre1">
                                                </div>
                                                <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zre2" style="font-weight: 800; font-size: large;"  formControlName="q_zre2">
                                                  </div>
                                                  <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zre3" style="font-weight: 800; font-size: large;"  formControlName="q_zre3">
                                                  </div>
                                                  <div class="col-sm-1">
                                                    <input type="number" class="form-control" id="q_zre4" style="font-weight: 800; font-size: large;"  formControlName="q_zre4">
                                                  </div>
                                              </div>
                                        </div>
                                        <h3 style="background-color: #055821; color:#FFFF; padding: 3px;">  COMPLEMENT PRONOSTIC</h3>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label style="color: red;">Ajouter le shema ici...</label>
                                                <input type="file" class="form-control"
                                                    formControlName="q_lienschema" (change)="onFileSelect($event)">
                                            </div>

                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="tel">Message aux clients</label>
                                                <input type="textarea " rows=3 class="form-control"
                                                    placeholder="Ecrire un message aux abonnées concernat le pronostic de ce quinté"
                                                    formControlName="q_message">
                                                <input type="text" class="form-control" placeholder="true ou false" 
                                                    formControlName="q_afficheresult" hidden>

                                            </div>
                                        </div>
                                        <div class="row col-12">
                                            <div class="col-md-4 col-sm-2"></div>
                                            <div class="col-md-4 col-sm-8" style="text-align: center;">
                                                <button class="btn btn-success btn-block" type="button"
                                                    (click)="onsubmitpronosticForm(pronosticForm.value)">ENREGISTREZ LE PRONOSTIC</button>
                                                    <hr>
                                                <!-- loader  -->
                                                <div class="spinner4" *ngIf="!isLoadingPronostic">
                                                    <div class="bounce1"></div>
                                                    <div class="bounce2"></div>
                                                    <div class="bounce3"></div>
                                                </div>
                                                <!-- loader end -->
                                            </div>
                                            <div class="col-md-4 col-sm-2"></div>
                                        </div>
                                 </div>

                                    <div *ngIf="affichePartant" [@simpleFadeAnimation]="'in'">
                                        <h3 style="background-color: #086A87; color:#FFFF; padding: 3px;margin: 50px 1px 0px 0px;">  PARTANTS</h3>
                                        <label *ngIf="afficherTable==0" style="color: red;">Ajouter le fichier des partants ici...</label>
                                        
                                            
                                    <form [formGroup]="partantForm">
                                        <table class="sjs-table" *ngIf="afficherTable!=0">
                                            <tr *ngFor="let row of data" >
                                              <td *ngFor="let val of row" >
                                                {{val}}
                                              </td>
                                            </tr>
                                          </table>
                                          <label *ngIf="afficherTable!=0" style="color: rgb(52, 129, 16);">Vous pouvez maintenant charger le fichier en base...</label>
                                        
                                        <div class="row col-12">
                                       
                                            <div class="col-lg-8 col-md-8 col-sm-12">
                                                <div class="form-group">
                                                   
                                                    <input type="file" class="form-control"
                                                    (change)="onFileChange($event)" multiple="false"/>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-6">
                                                <div class="form-group">
                                                    <div class="form-group">
                                                        <button 
                                                        class="btn btn-info"
                                                        [disabled]="afficherTable==0"
                                                        (click)="onSubmitPartant(partantForm.value)">
                                                        Charger le tableau</button>
                                                     </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-6">
                                                <div class="form-group">
                                                   <button class="btn btn-danger">Vider le tableau</button>
                                                </div>
                                            </div>
                                      
                                        </div>
                                  
                                           </form> 
                                             <!-- loader  -->
                                             <div class="spinner4" *ngIf="isLoadingPartant">
                                                <div class="bounce1"></div>
                                                <div class="bounce2"></div>
                                                <div class="bounce3"></div>
                                            </div>
                                            <!-- loader end -->
<div class="table-responsive">
     <table class="dataTable " class="sjs-table" mat-table
                                                    [dataSource]="dataSourceCheveau" matSort style="margin-top: 5px;">
                                                    
                                                    <ng-container matColumnDef="ch_numero">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center"> <b>N° Ordre</b>
                                                        </th>
                                                        <td  *matCellDef="let cheval"> {{cheval.ch_numero}} </td>
                                                    </ng-container>
                                                
                                                    <ng-container matColumnDef="ch_cheval">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center"> Cheval </th>
                                                        <td  *matCellDef="let cheval">
                                                            {{cheval.ch_cheval}} </td>
                                                    </ng-container>

                                                   
                                                    <ng-container matColumnDef="ch_sa">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center">
                                                            SA</th>
                                                        <td  *matCellDef="let cheval">
                                                            {{cheval.ch_sa}}
                                                        </td>
                                                    </ng-container>
                                                    
                                                    <ng-container matColumnDef="ch_dist">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center">
                                                            DISTANCE</th>
                                                        <td  *matCellDef="let cheval">
                                                            {{cheval.ch_dist}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ch_driver">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center">
                                                            DRIVER</th>
                                                        <td  *matCellDef="let cheval">
                                                            {{cheval.ch_driver}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ch_entraineur">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center">
                                                            ENTRAINEUR</th>
                                                        <td  *matCellDef="let cheval">
                                                            {{cheval.ch_entraineur}}
                                                        </td>
                                                    </ng-container>
                                                    
                                                    <ng-container matColumnDef="ch_gains">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center">
                                                            GAINS</th>
                                                        <td  *matCellDef="let cheval">
                                                            {{cheval.ch_gains}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ch_c">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center">
                                                            C</th>
                                                        <td  *matCellDef="let cheval">
                                                            {{cheval.ch_c}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ch_poids">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center">
                                                            POIDS</th>
                                                        <td  *matCellDef="let cheval">
                                                            {{cheval.ch_poids}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ch_valeur">
                                                        <th *matHeaderCellDef mat-sort-header 
                                                            style="width: 100px; text-align: center">
                                                            VALEUR</th>
                                                        <td  *matCellDef="let cheval">
                                                            {{cheval.ch_valeur}}
                                                        </td>
                                                    </ng-container>
                                                   
                                                   
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsattele"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsattele;">
                                                    </tr>
                                                </table>
</div>
                                           
                                        
                                          
                                    </div>
                                </div>

                                <div class="card-footer">
                                    <div class="row col-12">
                                        <div class="col-4"></div>
                                        <div class="col-4" style="text-align: center;">
                                            <span data-dismiss="modal" style="color: red; text-align:center;"> <a
                                                    style="cursor: pointer;">FERMER</a></span>
                                        </div>
                                        <div class="col-4"></div>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <!-- /.card -->
                    </div>
                </div>
                <!--div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-outline-light" data-dismiss="modal">Annuler</button>
                    <button type="button" class="btn btn-danger" (click)="supprime()" data-dismiss="modal">Supprimer</button>
                </div-->
            </div>

        </div>

    </div>
    <!--FIN MODAL PRONOSTIC-->

</div>