<body class="hold-transition login-page " [@simpleFadeAnimation]="'in'" style="background-image: url('assets/img/6-bgImages-homePage.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center;">
    <div class="login-box">

        <!-- /.login-logo -->
        <div class="card  card-outline card-red" style="opacity:0.85;">

            <div class="card-body login-card-body">
                <div class="row">
                    <div class="col-4">
                        <img src="assets/img/icon.png" alt="PMU ROUGE Logo">
                    </div>
                    <div class="col-8">
                        <h2 style="text-align: left; color:red">Connexion</h2>
                    </div>
                </div>
                <hr style="margin-top: -10px;">

                <!-- loader  -->
                <div class="spinner4" *ngIf="isLoadingResults">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
                <!-- loader end -->

                <div *ngIf='afficheErreur == true' class="alert alert-danger" style="font-size: small;">Identifiant et / ou mot de passe incorrect(s).</div>

                <form [formGroup]="loginFormGroup" (ngSubmit)="connexion(loginFormGroup.value)">
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="Email" formControlName="Util_Email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Mot de passe" formControlName="Util_Mdp">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <!--div class="row" style="background-color: brown; margin: 5px">
                        <span class="direct-chat-timestamp float-right">
                        Mot de place oublié ?
                    </span>
                    </div-->
                    <div class="direct-chat-infos clearfix">
                        <span class="direct-chat-name float-right" style="margin-top: -15px; cursor: pointer; font-size:xx-small"> <a routerLink='/mdpoublie' >  Mot de place oublié ?</a></span>
                    </div>

                    <div class="row" style="margin: 10px;">
                        <!--div class="col-7">
                            <div class="direct-chat-infos clearfix">
                                <span class="direct-chat-name float-left"><a routerLink='/register'>Pas encore inscrit ? </a></span>
                            </div>
                        </div-->
                        <!-- /.col -->
                        <div class="col-12">
                            <button type="submit" class="btn btn-success btn-block" [disabled]="!loginFormGroup.valid">Connexion</button>
                        </div>

                        <!-- /.col -->
                    </div>
                </form>

            </div>

            <!--  <div class="card-footer">
                <p class="mt-2">
                    <a routerLink='/mdpoublie' routerLinkActive="active">J'ai oublié mon mot de passe</a>
                </p>
                <p class="mb-0">
                    <a routerLink='/register' routerLinkActive="active" class="text-center">Creer mon compte</a>
                </p>
            </div>-->
            <!-- /.login-card-body -->
            <span class="users-list-date" style="text-align: center; color: red; margin-top:-15px"><b>PMU Rouge</b></span>

        </div>

    </div>
    <!-- /.login-box -->

</body>