import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  // urlTrombino = 'http://172.16.2.16:8060';
  // urlg = 'http://127.0.0.1:8080';
  // urlg = 'http://172.16.2.16:8034'; //API rti

  // urlg = 'http://127.0.0.1:8080'; //LOCAL
  urlg = 'https://api.pmurouge.com'; //API web pmu rouge TT
  idUser = localStorage.getItem('id');
  var_Reattribution;

  constructor() { }
}
