<div [@simpleFadeAnimation]="'in'">


    <!-- /.col -->
    <div class="row callout">
        <div class="card">
            <div class="card-header" style="background-color: #086A87; color: #FFFF; height: 50px  ">
                <h3 class="card-title">Edition Fiche Client</h3>
            </div>
        </div>
        <div class="col-md-12">
            <!-- general form elements -->
            <div class="card card-outline card-info">
                <!-- form start -->
                <!-- loader  -->
                <div class="spinner4" *ngIf="isLoadingResults">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
                <!-- loader end -->

                <form [formGroup]="clientForm" (ngSubmit)="onSubmitForm(clientForm.value)">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-9">
                                <div class="form-group">
                                    <span>NOM ET PRENOMS<b style="color: red;"> *</b></span>
                                    <input type="text" class="form-control" formControlName="cl_id" placeholder="Id" hidden>
                                    <input type="text" class="form-control" formControlName="cl_nomprenoms" placeholder="Nom et prénoms du client">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <span for="poste">DATE DE NAISSANCE</span>
                                    <input type="date" class="form-control" formControlName="cl_datenaissance">
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-3">
                                <div class="form-group">
                                    <span for="exampleInputEmail1">VILLE<b style="color: red;"> *</b></span>
                                    <input type="email" class="form-control" formControlName="cl_ville" placeholder="ville" required>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <span for="tel">ADDRESSE</span>
                                    <input type="text" class="form-control" formControlName="cl_adresse" placeholder="Boite postale">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <span for="poste">CODE POSTAL</span>
                                    <input type="text" class="form-control" formControlName="cl_codepostal" placeholder="Code postal">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <span for="exampleInputEmail1">EMAIL<b style="color: red;"> *</b></span>
                                    <input type="email" class="form-control" formControlName="cl_email" placeholder="Addresse electronique" required>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <span for="tel">TELEPHONE<b style="color: red;"> *</b></span>
                                    <input type="text" class="form-control" formControlName="cl_telephone" placeholder="Téléphone">
                                </div>
                            </div>
                            <div class="col-3">

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <span for="exampleInputEmail1">FORMULE</span>
                                    <ng-select formControlName="cl_idformule" [items]="listeFormule" bindLabel="f_designation" bindValue="id_formule">
                                    </ng-select>
                                    <!--select class="form-control" formControlName="cl_idformule">
                                        <option>mmmm</option>
                                    </select-->

                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <span for="tel">EXPIRE LE</span>
                                    <input type="date" class="form-control" formControlName="cl_dateexpireformule">
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="form-group">
                                    <label style="color: green;" for="statut">STAUT ACTIF</label>
                                    <input id="statut" type="checkbox" class="form-control" formControlName="cl_actif">
                                </div>
                            </div>

                        </div>

                        <div class="form-check" hidden>
                            <input type="checkbox" class="form-check-input" formControlName="cl_bloque" id="cl_bloque">
                            <label class="form-check-label" for="cl_bloque" style="font-size: 14px; color:red">COMPTE ADMINISTRATEUR</label>
                        </div>
                        <input type="text" class="form-check-input" formControlName="cl_idutilisateur" id="cl_idutilisateur" hidden>
                    </div>
                    <!-- /.card-body -->

                    <div class="card-footer">
                        <button class="btn btn-success" type="submit" [disabled]="!clientForm.valid">Enregistrer</button>
                        <button routerLink='/admin/admin/monespace' class="btn btn-warning" style="margin: 5px;">Fermer</button>
                    </div>
                </form>
            </div>
            <!-- /.card -->


        </div>

    </div>
</div>