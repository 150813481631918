import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ClientService } from 'src/app/services/client.service';
import { FormulaireService } from '../../services/formulaire.service';


@Component({
  selector: 'app-pageclient',
  templateUrl: './pageclient.component.html',
  styleUrls: ['./pageclient.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class PageclientComponent implements OnInit {

  clientForm: FormGroup;
  isLoadingResults = false;
  OneUser: any = [];
  id: string;
  listeDirection: any = [];
  direction: any = [];
  afficheReinit = false;
  reponse: any;
  actif = false;
  listeFormule: any;

  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private formulaireService: FormulaireService) { }

  ngOnInit() {
    this.initForm();
    this.loadlisteFormulaire();
    this.loadOneClient();

    if (this.id === '0') {
      this.afficheReinit = false;
    } else {
      this.afficheReinit = true;
    }
  }

  loadlisteFormulaire() {
    this.formulaireService.readallFormules().subscribe(ret => {
      this.reponse = ret;
      this.listeFormule = this.reponse.results;
    });
  }


  loadOneClient() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id !== '0') {
      this.clientService.readoneClient(this.id).subscribe(reponse => {
        this.clientForm.controls['cl_id'].setValue(reponse.results[0].cl_id);
        this.clientForm.controls['cl_nomprenoms'].setValue(reponse.results[0].cl_nomprenoms);
        this.clientForm.controls['cl_ville'].setValue(reponse.results[0].cl_ville);
        this.clientForm.controls['cl_adresse'].setValue(reponse.results[0].cl_adresse);
        this.clientForm.controls['cl_datenaissance'].setValue(reponse.results[0].cl_datenaissance);
        this.clientForm.controls['cl_codepostal'].setValue(reponse.results[0].cl_codepostal);
        this.clientForm.controls['cl_email'].setValue(reponse.results[0].cl_email);
        this.clientForm.controls['cl_telephone'].setValue(reponse.results[0].cl_telephone);
        this.clientForm.controls['cl_idformule'].setValue(reponse.results[0].cl_idformule);
        this.clientForm.controls['cl_dateexpireformule'].setValue(reponse.results[0].cl_dateexpireformule);
        this.clientForm.controls['cl_idutilisateur'].setValue(reponse.results[0].cl_idutilisateur);

        if (reponse.results[0].cl_actif !== 'true') {
          this.clientForm.controls['cl_actif'].setValue(false);
        } else {
          this.clientForm.controls['cl_actif'].setValue(true);
        }
        if (reponse.results[0].cl_bloque !== 'true') {
          this.clientForm.controls['cl_bloque'].setValue(false);
        } else {
          this.clientForm.controls['cl_bloque'].setValue(true);
        }

      });

    }
  }

  initForm() {
    this.isLoadingResults = false;
    this.clientForm = this.formBuilder.group({
      cl_id: [''],
      cl_nomprenoms: ['', Validators.required],
      cl_ville: ['', Validators.required],
      cl_adresse: [''],
      cl_datenaissance: [''],
      cl_codepostal: [''],
      cl_email: ['', Validators.email],
      cl_telephone: ['', Validators.required],
      cl_idformule: [''],
      cl_dateexpireformule: [''],
      cl_actif: [''],
      cl_bloque: [''],
      cl_idutilisateur: ['']
    });
  }

  onSubmitForm(f) {
    this.isLoadingResults = true;
    const clientFormData = new FormData();
    clientFormData.append('cl_id', f.cl_id);
    clientFormData.append('cl_nomprenoms', f.cl_nomprenoms);
    clientFormData.append('cl_ville', f.cl_ville);
    clientFormData.append('cl_adresse', f.cl_adresse);
    clientFormData.append('cl_datenaissance', f.cl_datenaissance);
    clientFormData.append('cl_codepostal', f.cl_codepostal);
    clientFormData.append('cl_email', f.cl_email);
    clientFormData.append('cl_telephone', f.cl_telephone);
    clientFormData.append('cl_idformule', f.cl_idformule);
    clientFormData.append('cl_dateexpireformule', f.cl_dateexpireformule);

    if (f.cl_actif == true) {
      clientFormData.append('cl_actif', 'true');
    } else {
      clientFormData.append('cl_actif', 'false');
    }
    if (f.cl_bloque == true) {
      clientFormData.append('cl_bloque', 'true');
    } else {
      clientFormData.append('cl_bloque', 'false');
    }

    // clientFormData.append('cl_idutilisateur', f.cl_idutilisateur);
   
    if (this.id === '0') {
      this.clientService.createClient(clientFormData).subscribe(result => {
        if (result.success == true) {
          this.toastr.success(result.message, 'PMU ROUGE');
          this.reinitform();
          this.isLoadingResults = false;
        } else {
          this.toastr.error(result.message, 'PMU ROUGE');
          this.isLoadingResults = false;
        }
      }, () => {
        this.toastr.error('Problème de connexion', 'PMU ROUGE');
        this.isLoadingResults = false;
      });
    } else {
      clientFormData.append('cl_idutilisateur', localStorage.getItem('id'));
      this.clientService.updateClient(clientFormData).subscribe(result => {
        if (result.success == true) {
          this.toastr.success(result.message, 'PMU ROUGE');
          this.location.back();
          this.isLoadingResults = false;
        } else {
          this.toastr.error(result.message, 'PMU ROUGE');
          this.isLoadingResults = false;
        }
      }, () => {
        this.toastr.error('Problème de connexion', 'PMU ROUGE');
        this.isLoadingResults = false;
      });
    }
  }

  reinitform() {
    this.clientForm.reset();
  }


}