<body class="hold-transition " [@simpleFadeAnimation]="'in'" style="background-color:transparent;">
    <div class="container ">
        <div class="direct-chat-infos clearfix">
            <span class="direct-chat-name float-right" style="margin-top: 05px; cursor: pointer; font-size:xx-small">
                <button routerLink='/auth' type="button" class="btn btn-block btn-outline-success btn-xs">J'ai déjà un
                    compte</button>
            </span>
        </div>
        <div class="row" style="height: 50px;">
            <img src="assets/img/background.jpg" alt="PMU ROUGE" width="100%">
        </div>
        <div class="row">
            <div class="row mt-4">
                <div class="col-sm-4">
                    <div class="position-fixe " style="height: 80px">
                        <img src="assets/img/logo-pmu-rouge-comingSoon.png" alt="PMU ROUGE" width="300px">
                    </div>


                </div>

                <div class="col-sm-8">
                    <div class="position-relative " style="height: 80px; color:white;">
                        <h1>INSCRIPTION PREMIUM PMU ROUGE</h1>
                        <h2 style="color: whitesmoke;">Veuillez renseigner les informations suivantes :</h2>

                    </div>

                </div>
            </div>
        </div>

    </div>

    <section id="ins" class="page-section vh-100">

        <div class='row pb-5 pt-3'>
            <div
                class="contentBlancIns m-auto col-xl-7 col-lg-8 col-md-8 col-sm-11 col-12 text-center p-sm-5 p-md-5 p-lg-5 p-xl-5">

                <div class='titreForm' style="color: whitesmoke;">Informations à compléter :</div>

                <!-- loader  -->
                <div class="spinner4" *ngIf="isLoadingResults">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
                <!-- loader end -->

                <form [formGroup]="clientForm" (ngSubmit)="onSubmitForm(clientForm.value)">
                    <input type="text" formControlName="cl_idformule" hidden />
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-md-12 padding-left-0 pb-4 pb-sm-0">
                                <input type="text" class="form-control " placeholder="Nom & Prénoms"
                                    formControlName="cl_nomprenoms"
                                    style="border-width: 1px; border-color: red; border-style: solid;">
                            </div>

                        </div>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control input-medium " placeholder="Email"
                            formControlName="cl_email"
                            style="border-width: 1px; border-color: red; border-style: solid;">

                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-md-6 padding-left-0 pb-4 pb-sm-0">
                                <input type="password" formControlName="cl_mdp" placeholder="Mot de passe"
                                    class="form-control"
                                    style="border-width: 1px; border-color: red; border-style: solid;">
                            </div>
                            <div class="col-12 col-md-6 padding-right-0">
                                <input type="password" formControlName="cl_mdpConf" class="form-control "
                                    placeholder="Confirmation du mot de passe"
                                    style="border-width: 1px; border-color: red; border-style: solid;">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class='row'>
                            <div class="col-12 col-md-6 pb-4 pb-sm-0 padding-left-0">
                                <input class="form-control" placeholder="Mobile" type="text"
                                    formControlName="cl_telephone"
                                    style="border-width: 1px; border-color: red; border-style: solid;">
                            </div>

                            <div class="col-12 col-md-6 padding-right-0">
                                <input class="form-control" type="date" placeholder="Date de naissance"
                                    formControlName="cl_datenaissance"
                                    style="border-width: 1px; border-color: red; border-style: solid;">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class='row'>
                            <div class="col-12 col-md-12 pb-4 pb-sm-0 padding-left-0">
                                <input type="text" class="form-control" placeholder="Adresse"
                                    formControlName="cl_adresse">
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class='row'>
                            <div class="col-12 col-md-6 pb-4 pb-sm-0 padding-left-0">
                                <input type="text" class="form-control" placeholder="Code postal"
                                    formControlName="cl_codepostal">
                            </div>

                            <div class="col-12 col-md-6 pb-4 pb-sm-0 padding-right-0">
                                <input type="text" class="form-control" placeholder="Ville" formControlName="cl_ville"
                                    style="border-width: 1px; border-color: red; border-style: solid;">
                            </div>
                        </div>
                    </div>

                    <!--div class="form-group">
                        <div class='row'>
                            <div class="col-12 px-0 py-1 text-left input-medium">
                                <input type="checkbox" name="cgv" class="mr-2" value="1" />J'ai lu et j'accepte les <a
                                    href='#' target='_blank'>conditions générales de vente</a>
                            </div>
                        </div>

                        <div class='row'>
                            <div class="col-12 px-0 py-1 text-left input-medium">
                                <input type="checkbox" name="cgudp" class="mr-2" value="1" />J'ai lu et j'accepte les <a
                                    href='#' target='_blank'>conditions générales d'utilisation des données
                                    personnelles</a>
                            </div>
                        </div>
                    </div-->

                    <div class=" row form-group">
                        <div class=" col-6 pb-4 pb-sm-0 padding-left-0">
                            <button type="submit" class="btn btn-success d-block w-100">Valider</button>
                        </div>
                        <div class=" col-6 pb-4 pb-sm-0 padding-right-0">
                            <button type="button" class="btn btn-warning d-block w-100" (click)="retour()">Choisir une
                                autre
                                formule</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </section>
</body>