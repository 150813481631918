<body class="hold-transition register-page" [@simpleFadeAnimation]="'in'" style="background-image: url('assets/img/home-courses-Fond.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center;">
    >
    <div class="register-box">
        <div class="card card-outline card-danger">

            <div class="card-body register-card-body">
                <div class="row">

                    <div class="col-4">
                        <img src="assets/img/icon.png" alt="PMU ROUGE Logo">
                    </div>
                    <div class="col-8">
                        <h2 style="text-align: left; color:red">Modifier votre Mot de Passe</h2>
                    </div>
                </div>
                <hr style="margin-top: -10px;">


                <!-- loader  -->
                <div class="spinner4" *ngIf="isLoadingResults">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
                <!-- loader end -->
                <form [formGroup]="modifFormGroup" (ngSubmit)="enregistrer(modifFormGroup.value)">

                    <div [ngClass]="{'afficheerror': erreurOld === true}" class="input-group mb-3">
                        <input type="password" class="form-control" formControlName='Util_MdpOld' placeholder="Ancien mot de passe">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="{'afficheerror': erreurNew === true}" class="input-group mb-3 ">
                        <input type="password" class="form-control" formControlName='Util_MdpNew' placeholder="Nouveau mot de passe">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="{'afficheerror': erreurNew === true}" class="input-group mb-3">
                        <input type="password" class="form-control" formControlName='Util_MdpNewR' placeholder="Resaisir le nouveau">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <!-- /.col -->
                        <div class="col-12">
                            <button type="submit" [disabled]='!modifFormGroup.valid' class="btn btn-primary btn-block btn-flat">Enregistrer</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>



            </div>

            <div class="card-footer">
                <a routerLink="/auth" class="text-center">J'ai déjà un compte</a>
            </div>
            <!-- /.form-box -->
        </div>
        <!-- /.card -->
    </div>
    <!-- /.register-box -->

</body>