import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class FormulaireService {
  urlG: string;
  urlT: string;
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient) {
    this.urlG = this.configService.urlg;
  }

  readallFormules(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/formules/listeformule.php');
  }

  allurl(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/formules/urllist.php');
  }

  readoneFormule(id: any): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/formules/formuleparid.php?f_index=' + id);
  }


  readonecheval(id: any): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/formules/chevalparid.php?id_chevaux=' + id);
  }
  ajoutatelle(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/ajoutatelle.php', data);
  }
  ajoutmonte(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/ajoutmonte.php', data);
  }
  ajoutplat(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/ajoutplat.php', data);
  }
  ajoutchase(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/ajoutchase.php', data);
  }
  createurl(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/ajouturl.php', data);
  }

  updatecheval(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/updatecheval.php', data);
  }
  readallcheveauparid(id): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/formules/listecheveaux.php?ch_q_id_manuel=' + id);
  }
  suponecheval(id): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/formules/supprimeonecheval.php?id_chevaux=' + id);
  }
  supurl(id): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/formules/supprimeoneurl.php?param_id=' + id);
  }

  readonequinte(id): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/formules/onequinte.php?id_quinte=' + id);
  }

  createquinte(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/ajoutquinte.php', data);
  }
  updatequinte(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/updatequinte.php', data);
  }
  updatepronostic(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/updatepronostic.php', data);
  }
  updateresultat(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/formules/updateresultat.php', data);
  }
  readallquintepronostic(): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/formules/listequintepronostic.php');
  }

  suponequintepronostic(id): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/formules/supprimeonequinte.php?id_quinte=' + id);
  }

  /*    createClient(data: any): Observable<any> {
      return this.httpClient.post<any>(this.urlG + '/clients/creationclient.php', data);
    }
  
    updateClient(data: any): Observable<any> {
      return this.httpClient.post<any>(this.urlG + '/clients/updateclient.php', data);
    }
  
    deleteClient(id: any): Observable<any> {
      return this.httpClient.delete<any>(this.urlG + '/clients/suppressionclient.php?cl_id=' + id);
    }*/

}
